import { render, staticRenderFns } from "./EditLiveBroadcast.vue?vue&type=template&id=032c5547&scoped=true"
import script from "./EditLiveBroadcast.vue?vue&type=script&lang=js"
export * from "./EditLiveBroadcast.vue?vue&type=script&lang=js"
import style0 from "./EditLiveBroadcast.vue?vue&type=style&index=0&id=032c5547&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "032c5547",
  null
  
)

export default component.exports